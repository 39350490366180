import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import ContentHeader from '../components/ContentHeader'
import ContentBody from '../components/ContentBody'

const NotFoundPage = () => (
  <Layout>
    <ContentHeader
      title={'404 - Seite nicht gefunden.'}
      meta={
        'Bitte entschuldigen Sie! Leider können wir die Seite, nach der Sie suchen, nicht finden.'
      }
    />
    <ContentBody>
      <div className="px-5 sm:px-0 my-5 lg:my-2">
        <p className="break-words mb-6 md:w-7/12 lg:mb-10 xl:w-6/12 leading-6">
          Was können Sie tun?
        </p>
        <ol>
          <li>
            <p className="break-words mb-6 md:w-7/12 lg:mb-6 xl:w-6/12 leading-6">
              Nutzen Sie die <strong>Zurück-Taste</strong> Ihres Browsers, um zur vorherigen Seite zu gelangen.
            </p>
          </li>
          <li>
            <div className="break-words mb-6 md:w-7/12 lg:mb-15 xl:w-6/12 leading-6">
              Navigieren Sie zu unserer 
              <Link to="/" aria-label="Homepage" className="button-primary ml-2 md:ml-4">
                Startseite
              </Link>
            </div>
          </li>
        </ol>
        
        <div className="break-words mb-6 md:w-7/12 lg:mb-15 xl:w-6/12 leading-6">
          Sollten die zuvor genannten Optionen nicht zur Lösung Ihres Problems beitragen, nehmen Sie gerne direkt 
          <Link to="/kontakt" aria-label="Kontakt" className="button-tertiary ml-2 mr-2">
            Kontakt
          </Link> 
          mit uns auf.
        </div>
      </div>
    </ContentBody>
  </Layout>
)

export default NotFoundPage
